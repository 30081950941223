import styled, { css } from "styled-components"
import { H1, H3, Instructions } from "../../../components/page-elements"
import { device } from "../../../utils/responsive"
export const Title = styled(H1)`
  text-transform: uppercase;
`

export const StudioImage = styled.img`
  width: 300px;
  height: 30vh;
  object-fit: cover;
  margin-right: 1rem;
  border-radius: 1rem;
`

export const StudioInstructionsTextContainer = styled.div`
  padding: 1rem;
  width: 90%;
  text-align: center;
`

export const StudioImagesContainer = styled.div`
  display: flex;
  flex-direction: col;
  width: auto;
  margin-top: 1rem;
  overflow-x: scroll;
  padding-left: 1rem;
  padding-right: 0;
`

export const HorizontalSpace = styled.div`
  width: 1rem;
`

export const MapContainer = styled.div`
  width: 100vw;
  height: calc(100vh - 100px);
  position: relative;
`

export const InstructionHelper = styled.div`
  position: absolute;
  padding: 0.5rem 1rem;
  top: 1rem;
  left: 1rem;
  box-shadow: 0 4px 10px rgba(60, 64, 67, 0.28);
  white-space: nowrap;
  background: white;
  border-radius: 1rem;
  cursor: pointer;

  &:hover {
    background: whitesmoke;
  }

  @media ${device.mobileS} {
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileL} {
  }
  @media ${device.mobileXL} {
  }
  @media ${device.tablet} {
    right: 1rem;
    left: unset;
  }
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
  }
`

export const MarkerAddress = styled.p`
  position: absolute;
  top: 50%;
  left: -130px;
  padding: 6px 12px;
  box-shadow: 0 4px 10px rgba(60, 64, 67, 0.28);
  margin-top: 2.5rem;
  white-space: nowrap;
  background: white;
  border-radius: 18px;

  @media ${device.mobileS} {
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileL} {
  }
  @media ${device.mobileXL} {
  }
  @media ${device.tablet} {
    left: ${props => {
      return props.index === 4 ? "0" : "-50px"
    }};
    top: ${props => {
      return props.index === 4 ? "-50px" : "-30px"
    }};
  }
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
  }
`

export const SideDrawerContainer = styled.div`
  position: absolute;
  //align-self: center;
  //vertical-align: center;
  left: 20px;
  //top: 20px;
  top: 50%;
  //left: 50%;
  transform: translate(0%, -50%);
  //height: auto;
  //bottom: 10px;
  width: 30%;
  background: white;
  box-shadow: 0 4px 10px rgba(60, 64, 67, 0.28);
  border-radius: 1rem;

  //display: flex;

  //overflow-y: scroll;

  overflow-x: hidden;

  display: none;

  @media ${device.mobileS} {
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileL} {
  }
  @media ${device.mobileXL} {
  }
  @media ${device.tablet} {
    display: flex;
    align-items: center;
    justify-content: center;

    width: auto;
    max-width: 40%;
    //max-height: 95%;
  }
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
  }
`

export const BottomDrawerContainer = styled.div`
  display: "block";

  @media ${device.mobileS} {
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileL} {
  }
  @media ${device.mobileXL} {
  }
  @media ${device.tablet} {
    display: none;
  }
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
  }
`

export const DefaultInstructionsContainer = styled.div`
  padding: 1rem;

  max-height: 80vh;

  @media ${device.tablet} {
    padding: 0;
  }
`

const commonInstructionsStyle = css`
  background-color: white;
  border-radius: 5px;
  //padding: 2rem;
  padding: 0.5rem;

  text-align: left;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07);
  & ul {
    padding: 1rem;
  }

  @media ${device.mobileS} {
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileL} {
  }
  @media ${device.mobileXL} {
  }
  @media ${device.tablet} {
    box-shadow: none;
    padding: 0.5rem;
    //padding: 2rem;

    //min-height: 100%;
    //max-height: 100%;
  }
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const InstructionsTitle = styled(H3)`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-transform: uppercase;
  letter-spacing: ${({ theme }) => theme.letterSpacing["4"]};
  line-height: ${({ theme }) => theme.lineHeight.normal};

  @media ${device.mobileS} {
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileL} {
  }
  @media ${device.mobileXL} {
  }
  @media ${device.tablet} {
    //margin-top: 150px;
    //font-weight: ${({ theme }) => theme.fontSize.bold};
    //font-size: ${({ theme }) => theme.fontSize["lg"]};
  }
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
  }
`
export const StyledInstructions = styled(Instructions)`
  width: 100%;
  ${commonInstructionsStyle}
`

export const StudioInstructions = styled(Instructions)`
  flex-shrink: 0;
  width: 100%;
  justify-content: space-between;
  ${commonInstructionsStyle};
`
export const StudiosContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const Studio = styled.div`
  display: flex;
  align-items: center;
`
