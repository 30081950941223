import React, { useEffect } from "react"
//import Map from "../../../components/map/map.component"
import "../../../components/map/map.css"
//import { H3 } from "../../../components/page-elements"
import { useStaticQuery, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
//import ExpandingPhotoCards from "../../../components/expanding-photo-cards/expanding-photo-cards.component"
import {
  InstructionsTitle,
  StyledInstructions,
  StudioInstructions,
  BottomDrawerContainer,
  SideDrawerContainer,
  DefaultInstructionsContainer,
  MarkerAddress,
  MapContainer,
  HorizontalSpace,
  StudioImage,
  StudioImagesContainer,
  StudioInstructionsTextContainer,
  InstructionHelper,
} from "./studios.styles"
import GoogleMapReact from "google-map-react"

import Drawer from "react-bottom-drawer"

import CustomMapStyle from "./mapstyle.json"

import "./studios.styles.css"

const Marker = React.memo(({ index, address, onClick = () => {} }) => {
  return (
    <div
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={Date.now()}
    >
      <MarkerAddress index={index}>{address}</MarkerAddress>
      <div className="pin"></div>
      <div className="pulse"></div>
    </div>
  )
})

const DrawerContent = React.memo(
  ({ element, defaultElement, selectedIndex, nodes }) => {
    if (selectedIndex !== 0) {
      return (
        <DefaultInstructionsContainer>
          <StudioInstructions>
            <StudioInstructionsTextContainer>
              <InstructionsTitle>
                {nodes[selectedIndex].node.frontmatter.title}
              </InstructionsTitle>
              <MDXRenderer>{nodes[selectedIndex].node.body}</MDXRenderer>
            </StudioInstructionsTextContainer>
            <StudioImagesContainer>
              {nodes[selectedIndex].photos.map(({ publicURL }) => {
                return (
                  <StudioImage
                    key={publicURL}
                    alt={nodes[selectedIndex].node.frontmatter.title}
                    src={publicURL}
                  />
                )
              })}

              <HorizontalSpace>&nbsp;</HorizontalSpace>
            </StudioImagesContainer>
          </StudioInstructions>
        </DefaultInstructionsContainer>
      )
    }

    if (defaultElement && defaultElement.node) {
      return (
        <DefaultInstructionsContainer>
          <StyledInstructions>
            <InstructionsTitle>
              {defaultElement.node.frontmatter.title}
            </InstructionsTitle>
            <MDXRenderer>{defaultElement.node.body}</MDXRenderer>
          </StyledInstructions>
        </DefaultInstructionsContainer>
      )
    }

    return null
  }
)

const Studios = () => {
  const {
    allMdx: { nodes },
    xbergPhotos,
    pbergPhotos,
  } = useStaticQuery(graphql`
    query StudiosQuery {
      xbergPhotos: allFile(
        filter: {
          extension: { eq: "jpg" }
          relativeDirectory: { eq: "studios/xberg" }
        }
      ) {
        nodes {
          publicURL
        }
      }
      pbergPhotos: allFile(
        filter: {
          extension: { eq: "jpg" }
          relativeDirectory: { eq: "studios/pberg" }
        }
      ) {
        nodes {
          publicURL
        }
      }
      allMdx(
        filter: { fileAbsolutePath: { regex: "/studios/" } }
        sort: { fields: [frontmatter___id] }
      ) {
        nodes {
          id
          frontmatter {
            id
            title
            location {
              address
              lat
              lg
            }
          }
          body
        }
      }
    }
  `)

  const [isVisible, setIsVisible] = React.useState(false)
  const closeDrawer = React.useCallback(() => setIsVisible(false), [])
  const [selectedNode, setSelectedNode] = React.useState(null)
  const [selectedIndex, setSelectedIndex] = React.useState(0)

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true)
    }, 2000)
  }, [])

  nodes.sort((a, b) => a.frontmatter.id - b.frontmatter.id)

  if (nodes.length > 0) {
    return (
      <MapContainer>
        <GoogleMapReact
          options={{
            styles: CustomMapStyle,
            fullscreenControl: false,
            zoomControl: false,
          }}
          draggable={false}
          bootstrapURLKeys={{ key: "AIzaSyBLVTZ9zP9DpuYu1dYedowac_aSIhzSA4o" }}
          defaultCenter={{ lat: 52.5, lng: 13.41 }}
          defaultZoom={12}
        >
          {[
            { node: nodes[1], photos: xbergPhotos.nodes, index: 1 },
            { node: nodes[2], photos: pbergPhotos.nodes, index: 2 },
            { node: nodes[3], photos: [], index: 3 },
            { node: nodes[4], photos: [], index: 4 },
            { node: nodes[5], photos: [], index: 5 },
          ].map((element, index) => {
            return (
              <Marker
                key={String(index)}
                index={index}
                lat={element.node.frontmatter.location.lat}
                lng={element.node.frontmatter.location.lg}
                address={element.node.frontmatter.location.address}
                onClick={() => {
                  if (index > 1) return
                  setSelectedNode(element)
                  setSelectedIndex(element.index)
                  setIsVisible(true)
                }}
              />
            )
          })}
        </GoogleMapReact>

        <SideDrawerContainer>
          <DrawerContent
            selectedIndex={selectedIndex}
            node={selectedNode}
            defaultElement={{ node: nodes[0], photos: [] }}
            nodes={[
              { node: nodes[0], photos: xbergPhotos.nodes, index: 1 },
              { node: nodes[1], photos: xbergPhotos.nodes, index: 1 },
              { node: nodes[2], photos: pbergPhotos.nodes, index: 2 },
            ]}
          />
        </SideDrawerContainer>

        <BottomDrawerContainer>
          <Drawer
            duration={250}
            hideScrollbars={true}
            onClose={closeDrawer}
            isVisible={isVisible}
          >
            <DrawerContent
              selectedIndex={selectedIndex}
              node={selectedNode}
              defaultElement={{ node: nodes[0], photos: [] }}
              nodes={[
                { node: nodes[0], photos: xbergPhotos.nodes, index: 1 },
                { node: nodes[1], photos: xbergPhotos.nodes, index: 1 },
                { node: nodes[2], photos: pbergPhotos.nodes, index: 2 },
              ]}
            />
          </Drawer>
        </BottomDrawerContainer>

        {selectedIndex !== 0 && (
          <InstructionHelper
            index={0}
            onClick={() => {
              setSelectedIndex(0)
              setIsVisible(true)
            }}
          >
            <p>Sudio Instructions</p>
          </InstructionHelper>
        )}
      </MapContainer>
    )
  }

  return null
}

export default React.memo(Studios)
